<template>
  <v-app>
    <v-main>
      <Home />
    </v-main>
  </v-app>
</template>

<script>
import Home from '@/components/Home.vue';

export default {
  name: 'app',
  components: {
    Home,
  },
};
</script>
