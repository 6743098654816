import { render, staticRenderFns } from "./Invest.vue?vue&type=template&id=79ef29b0&scoped=true"
import script from "./Invest.vue?vue&type=script&lang=js"
export * from "./Invest.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ef29b0",
  null
  
)

export default component.exports