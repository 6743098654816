<template>
  <v-dialog v-model="contactModalShown" eager width="700" persistent>
    <form ref="form" @submit.prevent="sendEmail">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon color="red" @click.stop="closeDialog">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-text-field v-model="from_name" name="from_name" label="Full Name" required autofocus
              :error="nameHasErrors" :color="nameHasErrors ? 'red' : 'grey'"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="from_email" name="from_email" label="Email" required :error="emailHasErrors"
              :color="emailHasErrors ? 'red' : 'grey'"></v-text-field>
          </v-col>
          <v-col>
            <v-textarea v-model="message" name="message" outlined label="Tell us how we can win your business"
              :error="messageHasErrors" :color="messageHasErrors ? 'red' : 'grey'"></v-textarea>
            <div class="g-recaptcha" data-sitekey="6LfEhnYpAAAAAHbbKkAmtYa5xdM8247S3mTr_brM"
              data-callback="setCaptchaPressed"></div>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" outlined large @click.stop="closeDialog">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined large @click.stop="sendEmail"
            :disabled="this.formHasErrors">Send</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    contactModalShown: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      nameHasErrors: false,
      emailHasErrors: false,
      messageHasErrors: false,
      dialogShown: this.contactModalShown,
      from_name: "",
      from_email: "",
      to_name: "MKPlus",
      message: "",
      captchaPressed: false
    };
  },
  watch: {
    from_name(newValue, oldValue) {
      this.nameHasErrors = this.fieldValidation("from_name", newValue)
    },
    from_email(newValue, oldValue) {
      this.emailHasErrors = this.fieldValidation("from_email", newValue)
    },
    message(newValue, oldValue) {
      this.messageHasErrors = this.fieldValidation("message", newValue)
    },
  },
  mounted: function () {
    window.setCaptchaPressed = this.setCaptchaPressed;
  },
  computed: {
    formHasErrors() {
      return this.from_name.length < 1 || this.from_email.length < 1 || this.message.length < 1 ||
        this.nameHasErrors || this.emailHasErrors || this.messageHasErrors || !this.captchaPressed
    }
  },
  methods: {
    setCaptchaPressed() {
      this.captchaPressed = true;
      // console.log('setCaptchaPressed: ' + this.captchaPressed)
    },
    fieldValidation(field, newValue) {
      let hasErrors = true
      switch (field) {
        case "from_name":
          hasErrors = newValue.length < 2
          break;
        case "from_email":
          hasErrors = newValue.length < 5 || !newValue.includes("@") || !newValue.includes(".")
          break;
        case "message":
          hasErrors = newValue.length < 20
          break;
        default:
          break;
      }
      // console.log('\n\nfieldValidation hasErrors: ' + hasErrors + " - " + newValue)
      return hasErrors
    },
    closeDialog() {
      this.dialogShown = false;
      this.$emit('update:contactModalShown', this.dialogShown);
    },
    sendEmail() {
      emailjs
        .sendForm('service_j9t27ag', 'template_6fktmaz', this.$refs.form, {
          publicKey: 'KiDlnFYpOSFgPWMJc',
          'g-recaptcha-response': '6LfEhnYpAAAAAHbbKkAmtYa5xdM8247S3mTr_brM'
        })
        .then(
          () => {
            this.closeDialog();
            console.log('Email sent!');
          },
          (error) => {
            console.log('Email was not sent. Error: ', error.text);
          },
        );
    },
  }
};
</script>

<style scoped></style>