<template>
  <v-dialog v-model="investModalShown" eager width="700">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon color="red" @click.stop="closeDialog">mdi-close-circle</v-icon>
      </v-card-title>
      <v-card-text>
        <div>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScNxGKiCA8vm8dMb8O-3kwP7BbhML5pS4eiaQg-ghj7EZ4c3Q/viewform?embedded=true"
            width="640"
            height="1400"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            >Loading…</iframe
          >
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" outlined large @click.stop="closeDialog"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Invest',
  props: {
    investModalShown: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      dialogShown: this.investModalShown,
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.dialogShown = false;
      this.$emit('update:investModalShown', this.dialogShown);
    },
  },
};
</script>

<style scoped></style>
